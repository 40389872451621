// require('./bootstrap');

require('alpinejs');
window.Swal = require('sweetalert2');
window.axios = require('axios');

window.ToggleButton = () => {
    return {
        status: true,
        toggle() {
            this.status = !this.status;
        }
    }
}
